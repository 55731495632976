import React from 'react';

import AboutUsImage from './../../../../assets/images/home-aboutus.jpg';

// import styles
import styles from './styles.module.scss';

const AboutUsSection: React.FC<{}> = (props) => {
  return <div className={styles.container}>
    
    <h2>Tentang Kami</h2>
    <div className={styles.wrapper}>
        <div className={styles.image}>
            <img src={AboutUsImage} />
        </div>
        <div className={styles.vision}>
            <div className={styles.section}>
                <h3>PT. Pilar Intan Konstruksi</h3>
                <div className={styles.tagsContainer}>
                  <span className={styles.tag}>Bertanggung Jawab</span>
                  <span className={styles.tag}>Integritas</span>
                  <span className={styles.tag}>Transparan</span>
                  <span className={styles.tag}>Harga bersaing</span>
                </div>
                <span className={styles.text}>Terjun di bidang konstruksi sejak 1996 dan telah mengerjakan lebih dari 150 proyek pembangunan konstruksi rumah tinggal, gedung bertingkat rendah hingga gedung high rise.</span>
                <br/><br/>
                <span className={styles.text}>Membangun dengan hati adalah kunci bagi kami bisa menyelesaikan semua proyek yang telah dipercayakan.</span>
            </div>
            <div className={styles.section}>
                <h3>Visi</h3>
                <span className={styles.text}>Membangun bangunan yang kokoh untuk menjadi bagian dari pilar usaha Indonesia.</span>
            </div>
            <div className={styles.section}>
                <h3>Misi</h3>
                <span className={styles.text}>Memberikan kualitas pembangunan terbaik dengan harga bersaing dan transparan.</span>
            </div>
        </div>
    </div>
  </div>
};

export default AboutUsSection;
