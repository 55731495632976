import React from 'react';
import ContactComponent from '../../components/contact';
import HeaderComponent from '../../components/header';
import About from './sections/abouts';
import Banner from './sections/banner';
import Clients from './sections/clients';
import Portfolio from './sections/portfolio';
import Services from './sections/services';

// import styles
import styles from './styles.module.css';

type HomePageProps = {};

const HomePage: React.FC<HomePageProps> = (props) => {
  return (
    <section className={styles.container}>
      <Banner />
      <Clients />
      <About />
      <Services />
      <Portfolio />
      <ContactComponent />
    </section>
  );
};

export default HomePage;
