import React from 'react';

// import styles
import styles from './styles.module.scss';

// import assets
import images from '../../../../assets/images/construction-Jobs.jpg';
import { NavLink } from 'react-router-dom';

type BannerProps = {};

const Banner: React.FC<BannerProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
          <h2 className={styles.header}>
            <p className={styles.sub}>menjadi bagian dari</p>
            <p className={styles.main}>pilar usaha anda</p>
          </h2>
          <p>Konsultasikan pembangunan anda dengan kami</p>
          <NavLink to={'/aboutus#contact'}><button className={styles.btnContact}>Kontak Kami</button></NavLink>
        </div>
        <div className={styles.imageContainer}> </div>
      </div>
      <div className={styles.clip} />
    </div>
  );
};

export default Banner;
