import React from 'react';
import MainPage from './pages';

// import pages
// import components

type AppProps = {};

const App: React.FC<AppProps> = (props) => {
  return <MainPage />;
};

export default App;
