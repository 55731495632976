import React from 'react';

// import styles
import styles from './styles.module.scss';

// import assets
import images from '../../../../assets/images/company.jpg';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

type AboutProps = {};

const About: React.FC<AboutProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <h2 className={styles.sub}>Tentang Kami</h2>
            <span className={styles.main}>PT. Pilar Intan Konstruksi</span>
          </div>
          <div className={styles.content}>
            <span className={styles.text}>Terjun di bidang konstruksi sejak 1996 dan telah mengerjakan lebih dari 150 project pembangunan konstruksi rumah tinggal, pabrik, kawasan perumahan, gedung bertingkat rendah sampai gedung high rise.</span>
            <br /><br />
            <span className={styles.text}>Membangun dengan hati adalah kunci bagi kami bisa menyelesaikan semua proyek yang telah dipercayakan.</span>
          </div>
          <div className={styles.tagsContainer}>
            <span className={styles.tag}>Bertanggung Jawab</span>
            <span className={styles.tag}>Integritas</span>
            <span className={styles.tag}>Transparan</span>
            <span className={styles.tag}>Harga bersaing</span>
          </div>
          <div className={styles.readMore}>
            <NavLink to={'/aboutus'}>Baca selengkapnya <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 3 }} /></NavLink>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.image1} />
          <div className={styles.image2} />
        </div>
      </div>
    </div>
  );
};

export default About;
