import React, { useEffect } from 'react';
import ContactComponent from '../../components/contact';

import AboutUsImage from './../../assets/images/home-aboutus.jpg';
import AboutUsSection from './sections/aboutus';
import LocationSection from './sections/location';

// import styles
import styles from './styles.module.scss';

const AboutUsPage: React.FC<{}> = (props) => {
  const scrollToHash = () => {

    /* Obtain hash from current location (and trim off leading #) */
    const id = window.location.hash.substring(1);

      if(id) {
          /* Find matching element by id */
          const anchor = document.getElementById(id);

          if(anchor) {
              /* Scroll to that element if present */
              anchor.scrollIntoView();
          }
      }
  }

  useEffect(() => {
    scrollToHash();
  }, []);

  return <div className={styles.container}>
    <AboutUsSection />
    <LocationSection />
    <ContactComponent />
  </div>
};

export default AboutUsPage;
