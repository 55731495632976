import { IProjectResorceShort } from './../props/project';
import Request from './request';

// import interfaces

const InquiryService = {
  create: async (data: FormData): Promise<IProjectResorceShort[]> => {
    try {
      const res = await Request.post(`/api/inquiries`, data);
      return res;
    } catch (error) {
      throw error;
    }
  }
};

export default InquiryService;
