import React from 'react';

// import styles
import styles from './styles.module.scss';

// logo
import InacoLogo from '../../../../assets/images/clients/inaco.png';
import MayoraLogo from '../../../../assets/images/clients/mayora.png';
import SmartfrenLogo from '../../../../assets/images/clients/smartfren.png';
import QbigLogo from '../../../../assets/images/clients/qbig.png';
import AdidasLogo from '../../../../assets/images/clients/adidas.png';
import TransmartLogo from '../../../../assets/images/clients/transmart.png';
import IndahKiatLogo from '../../../../assets/images/clients/indah kiat.png';
import MsaLogo from '../../../../assets/images/clients/msa.png';
import GramediaLogo from '../../../../assets/images/clients/gramedia.png';
import PimLogo from '../../../../assets/images/clients/pim.png';

type ClientsProps = {};

interface IClientProps {
  key: string;
  title: string;
  src: string;
};

const Clients: React.FC<ClientsProps> = (props) => {
  const clients: IClientProps[] = [
    { key: 'inaco', title: 'inaco', src: InacoLogo },
    { key: 'mayora', title: 'mayora', src: MayoraLogo },
    { key: 'smartfren', title: 'smartfren', src: SmartfrenLogo },
    { key: 'qbig', title: 'qbig', src: QbigLogo },
    { key: 'adidas', title: 'adidas', src: AdidasLogo },
    { key: 'transmart', title: 'transmart', src: TransmartLogo },
    { key: 'indahkiat', title: 'indahkiat', src: IndahKiatLogo },
    { key: 'msa', title: 'msa', src: MsaLogo },
    { key: 'gramedia', title: 'gramedia', src: GramediaLogo },
    { key: 'pim', title: 'pondok indah mall', src: PimLogo }
  ]

  return <div className={styles.container}>
    <div className={styles.wrapper}>
      <h2 className={styles.header}>klien kami</h2>
      <div className={styles.logoContainer}>
        {clients.map((client) => {
          return <div key={client.key} className={styles.clientLogo}>
            <img src={client.src} title={client.title} />
          </div>;
        })}
      </div>
    </div>
  </div>;
};

export default Clients;
