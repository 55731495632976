import { faBars, faClose, faHamburger } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './../../assets/images/logo.png';
import LogoInvert from './../../assets/images/logo-invert.png';

// import antd

// import styles
import styles from './styles.module.scss';

type HeaderComponentProps = {};

const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const links = [
    {key: 'home', link: '/', name: 'beranda'},
    {key: 'portfolio', link: '/projects', name: 'portfolio'},
    {key: 'aboutus', link: '/aboutus', name: 'tentang kami'},
    {key: 'contact', link: '/aboutus#contact', name: 'kontak'}
  ];

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logoContainer}>
          <NavLink to={"/"}><img src={Logo} /></NavLink>
        </div>
        <div className={styles.linkContainer}>
          {links.map((link) => <NavLink key={link.key} to={link.link} className={styles.link}>{link.name}</NavLink>)}
        </div>
        <div className={styles.mobileLink}>
          <button className={styles.menuButton} onClick={() => setMenuOpened(true)}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={`${styles.menuPanel} ${menuOpened ? styles.expanded : ''}`}>
            <div className={styles.panelHeader}>
              <img src={LogoInvert} />
              <button className={styles.closePanelButton} onClick={() => setMenuOpened(false)}><FontAwesomeIcon icon={faClose} fontSize={28} /></button>
            </div>
            <div className={styles.mobileLinkContainer}>
              {links.map((link) => <NavLink onClick={() => setMenuOpened(false)} key={link.key} to={link.link} className={styles.link}>{link.name}</NavLink>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
