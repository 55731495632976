import { IProjectResorceShort } from './../props/project';
import Request from './request';

// import interfaces

const ProjectServices = {
  retrieve: async (isPromoted?: boolean, limit?: number): Promise<IProjectResorceShort[]> => {
    try {
      const res = await Request.get(`/api/projects?isPromoted=${isPromoted ? 1 : 0}&${limit !== undefined ? 'limit=' + limit : ''}`);
      return res;
    } catch (error) {
      throw error;
    }
  }
};

export default ProjectServices;
