import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ContactComponent from '../../components/contact';
import GeneralConfig from '../../config';
import { IProjectResorceShort } from '../../props/project';
import ProjectServices from '../../services/project';

import AboutUsImage from './../../assets/images/home-aboutus.jpg';

// import styles
import styles from './styles.module.scss';

const ProjectsPage: React.FC<{}> = (props) => {
    const [projects, setProjects] = useState<IProjectResorceShort[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const init = async () => {
        const results = await ProjectServices.retrieve();
        setLoaded(true);
        setProjects(results);
    }

    useEffect(() => {
        init();
    }, []);
    
    const renderProjectCard = (project: IProjectResorceShort) => {
        return <div className={styles.project}>
            <div className={styles.image}>
                {project.files && project.files[0] ? <img src={GeneralConfig.assetsHostname + '/' + project.files[0].url} /> : null}
                {!project.files || !project.files[0] ? <div className={styles.noImage}><FontAwesomeIcon icon={faImage} fontSize={38} /></div> : null}
            </div>
            <div className={styles.overlay} />
            <div className={styles.content}>
                <span className={styles.text}>{project.name}</span>
            </div>
        </div>
    }

    const renderProjectList = (project: IProjectResorceShort) => {
        return <div className={styles.projectText}>
            <span>{project.name}</span>
        </div>
    }

    const renderProjects = () => {
        const promotedProjects = projects.filter((p) => p.isPromoted);
        const notPromotedProjects = projects.filter((p) => !p.isPromoted);

        return <div className={styles.projectRoot}>
            <h3>Proyek Terbaru</h3>
            <div className={styles.projectContainer}>
                {promotedProjects.map((project) => renderProjectCard(project))}
            </div>
            <h3>Proyek Lainnya</h3>
            <div>
                {notPromotedProjects.map((project) => renderProjectList(project))}
            </div>
        </div>;
    }
    
  return <div className={styles.container}>
    <h2>Portfolio</h2>
    {loaded ? renderProjects() : null}
  </div>
};

export default ProjectsPage;
