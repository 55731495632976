import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FooterComponent from '../components/footer';
import HeaderComponent from '../components/header';
import GeneralConfig from '../config';
import AboutUsPage from './aboutus';
import HomePage from './home';
import ProjectsPage from './projects';

// import styles
import styles from './styles.module.scss';

type MainPageProps = {};

const MainPage: React.FC<MainPageProps> = (props) => {
  return <div className={styles.global}>
    <BrowserRouter>
      <HeaderComponent />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/aboutus' element={<AboutUsPage />} />
        <Route path='/projects' element={<ProjectsPage />} />
      </Routes>
      <FooterComponent />
    </BrowserRouter>
    <a target={"_blank"} href={`https://wa.me/${GeneralConfig.whatsappNumber}?text=Hi, saya ingin konsultasi mengenai proyek pembangunan saya`} className={styles.floatingWhatsapp}><FontAwesomeIcon icon={faWhatsapp} /></a>
  </div>
};

export default MainPage;
