import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import GoogleMapReact from 'google-map-react';

// import styles
import styles from './styles.module.scss';
import GeneralConfig from '../../../../config';
import { IOfficeProps } from '../../../../components/footer';

const LocationSection: React.FC<{}> = (props) => {
    const LocationPin = (p: any) => <div className={styles.pin}>
        <FontAwesomeIcon icon={faLocationDot} className={styles.icon} fontSize={24} />
        <p className={styles.text}>{p.text}</p>
    </div>;

    const renderOfficeLocation = (office: IOfficeProps) => {
        return <div className={styles.office} style={{width: `${100 / GeneralConfig.offices.length}%`}}>
            <div className={styles.address}>
                <div>
                    <div style={{marginBottom: 20}}>
                        <h3 className={styles.header}>{office.title}</h3>
                        <p className={styles.text} style={{marginBottom: 10}}>{office.address}</p>
                        <a className={styles.openInMaps} target={'_blank'} href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${office.coordinate.lat},${office.coordinate.lng}`}>
                        Buka di Google Maps <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.map}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GeneralConfig.mapKey }}
                    defaultCenter={office.coordinate}
                    defaultZoom={15.5}
                >
                    <LocationPin {...office.coordinate} text={office.title} />
                </GoogleMapReact>
            </div>
        </div>
    }

  return <div className={styles.container}>
    <div className={styles.wrapper}>
        {GeneralConfig.offices.map((of) => renderOfficeLocation(of))}
    </div>
  </div>
};

export default LocationSection;
