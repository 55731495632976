import React from 'react';

// import styles
import styles from './styles.module.scss';

// import assets
import images from '../../../../assets/images/construction-1.jpg';

type ServicesProps = {};

const Services: React.FC<ServicesProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.sideLeft}>
        <div className={styles.content}>
          <p className={styles.number}>01</p>
          <p className={styles.text}>commercial building construction</p>
        </div>
        <div className={styles.overlay} />
      </div>
      <div className={styles.sideRight}>
        <div className={styles.content}>
          <p className={styles.number}>02</p>
          <p className={styles.text}>residential construction</p>
        </div>
        <div className={styles.overlay} />
      </div>
    </div>
  );
};

export default Services;
