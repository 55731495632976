import React, { useEffect } from 'react';

// import styles
import styles from './styles.module.scss';

// import assets
import project from '../../../../assets/images/project.jpg';
import { useState } from 'react';
import { IProjectResorceShort } from '../../../../props/project';

import ProjectImage from '../../../../assets/images/project.jpg';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage } from '@fortawesome/pro-light-svg-icons';
import ProjectServices from '../../../../services/project';
import GeneralConfig from '../../../../config';

type PortfolioProps = {};

const Portfolio: React.FC<PortfolioProps> = (props) => {
  const [projects, setProjects] = useState<IProjectResorceShort[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  /*const projects: IProjectProps[] = [
    {id: '1', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '2', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '3', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '4', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '5', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '6', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '7', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage},
    {id: '8', name: 'Stadion Jakabaring Palembang', imageUrl: ProjectImage}
  ];*/

  const init = async () => {
    const projects = await ProjectServices.retrieve(true, 8);
    setProjects(projects);
    setLoaded(true);
  }

  useEffect(() => {
    init();
  }, []);

  const renderProjectCard = (project: IProjectResorceShort) => {
    return <div className={styles.project}>
      {loaded ? <>
        {project.files && project.files[0] ? <img className={styles.image} src={GeneralConfig.assetsHostname + '/' + project.files[0].url} alt='project' /> : null}
        {!project.files || !project.files[0] ? <div className={styles.noImage}><FontAwesomeIcon icon={faImage} fontSize={48} /></div> : null}
        <div className={styles.overlay} />
        <div className={styles.content}>
          <span className={styles.text}>{project.name}</span>
        </div>
      </> : null}
    </div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2 className={styles.sub}>Portfolio</h2>
          <span className={styles.main}>Pembangunan Terbaru</span>
        </div>
      </div>
      <div className={styles.projectsContainer}>
        {projects.map((project) => renderProjectCard(project))}
      </div>
      <div className={styles.link}>
        <NavLink to={'/projects'}>Lihat semua portfolio <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 3 }} /></NavLink>
      </div>
    </div>
  );
};

export default Portfolio;
