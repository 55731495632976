import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import InquiryService from '../../services/inquiry';
import Logo from './../../assets/images/logo.png';

// import antd

// import styles
import styles from './styles.module.scss';

type ContactComponentProps = {};

const ContactComponent: React.FC<ContactComponentProps> = (props) => {
    const [name, setName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const _onSubmit = async () => {
        try {
            let error = false;
            setError(undefined);
            setLoading(true);
            setSubmitted(false);

            if (name === "" || phoneNumber === "" || notes === "") {
                setError("Nama, nomor telepon, dan detail tidak boleh kosong.");
                error = true;
            }

            if (!error) {
                const data = new FormData();
                data.append('name', name);
                data.append('phone', phoneNumber);
                data.append('notes', notes);
                await InquiryService.create(data);

                setLoading(false);
                setSubmitted(true);
            } else {
                throw new Error();
            }
        } catch(e) {
            setLoading(false);
        }
    }

  return (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <div className={styles.leftContainer}>
                <span> </span>
                <div className={styles.overlay}></div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.wrapper}>
                    <h2 className={styles.header}>Kontak Kami</h2>
                    {error ? <p className={styles.error}>{error}</p> : null}
                    <div className={styles.inputContainer}>
                        <label>Nama</label>
                        <input disabled={loading || submitted} type="text" value={name} onChange={(ev) => setName(ev.currentTarget.value)} />
                    </div>
                    <div className={styles.inputContainer}>
                        <label>Nomor Telepon</label>
                        <input disabled={loading || submitted} type="text" value={phoneNumber} onChange={(ev) => setPhoneNumber(ev.currentTarget.value)} />
                    </div>
                    <div className={styles.inputContainer}>
                        <label>Detail / Pesan</label>
                        <textarea disabled={loading || submitted} rows={5} value={notes} onChange={(ev) => setNotes(ev.currentTarget.value)} />
                    </div>
                    <div className={styles.inputContainer}>
                        {!loading && !submitted ? <button onClick={_onSubmit}>Submit</button> : null}
                        {loading && !submitted ? <span>Harap tunggu ...</span> : null}
                        {submitted ? <p className={styles.success}><FontAwesomeIcon icon={faCheckCircle} /> Pesan anda telah kami terima. Tim terkait akan segera menghubungi anda.</p> : null}
                    </div>
                </div>
            </div>
            </div>
    </div>
  );
};

export default ContactComponent;
