import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import GeneralConfig from '../../config';
import Logo from './../../assets/images/logo.png';

// import antd

// import styles
import styles from './styles.module.scss';

type FooterComponentProps = {};

export interface IOfficeProps {
  title: string;
  address: string;
  coordinate: {lat: number; lng: number;};
}

const FooterComponent: React.FC<FooterComponentProps> = (props) => {
  const renderOfficeDetail = (office: IOfficeProps) => {
    return <div className={styles.address}>
        <h3 className={styles.header}>{office.title}</h3>
        <p className={styles.text} style={{marginBottom: 10}}>{office.address}</p>
        <a className={styles.openInMaps} target={'_blank'} href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${office.coordinate.lat},${office.coordinate.lng}`}>
          Buka di Google Maps <FontAwesomeIcon icon={faArrowRight} />
        </a>
    </div>
  }

  return (
    <div className={styles.container} id={'contact'}>
      <div className={styles.wrapper}>
        <div className={styles.logoContainer}>
          <img src={Logo} />
        </div>
        <div className={styles.rightContainer}>
            {GeneralConfig.offices.map((of) => renderOfficeDetail(of))}
            <div className={styles.contact} style={{minWidth: 200}}>
                <h3 className={styles.header}>Kontak</h3>
                <a className={styles.link} target={'_blank'} href={`mailto:${GeneralConfig.emailAddress}`}><FontAwesomeIcon icon={faEnvelope} style={{marginRight: 3}} /> konstruksi@pilarintan.com</a>
                <a className={styles.link} target={'_blank'} href={`https://wa.me/${GeneralConfig.whatsappNumber}?text=Hi, saya ingin konsultasi mengenai proyek pembangunan saya`}><FontAwesomeIcon icon={faWhatsapp} style={{marginRight: 4, marginLeft: 1}} /> 0813-1555-7138</a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
