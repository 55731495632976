const GeneralConfig = {
  homepage: 'https://pilarintan.com',
  apiHostname: 'https://api.pilarintan.com',
  hostname: 'https://pilarintan.com',
  assetsHostname: 'https://api.pilarintan.com',
  /*homepage: 'http://localhost:3000',
  apiHostname: 'http://localhost:8000',
  hostname: 'https://localhost:3000',
  assetsHostname: 'http://localhost:8000',*/
  mapKey: 'AIzaSyAlTa5QVjz73sDNNGze_WaZFs_E_-rvLsE',
  dateFormat: 'DD/MM/YYYY',
  googleClientId: '',
  facebookAppId: '',
  offices: [
    {
      title: "Office",
      address: "Jl. Soekarno, Ruko Pisa Grande 2 No. J17, Curug Sangereng, Kecamatan Kelapa 2, Kabupaten Tangerang, 15810",
      coordinate: {
        lat: -6.261342,
        lng: 106.629555
      }
    }
  ],
  whatsappNumber: '6281315557138',
  emailAddress: 'konstruksi@pilarintan.com'
};

export default GeneralConfig;
